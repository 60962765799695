/* Need  js-info-bubble/src/infobubble.js */

// Locator
// Locator with or without Google Maps
$(function () {
  'use strict';

  var $locators = $('.js-locator');

  $locators.each(function (index, locator) {
    var $locator = $(locator),
      $map = $locator.find('.js-locator-map'),
      $list = $('#' + $locator.data('items')),
      $form = $locator.find('.js-locator-search'),
      $input = $form.find('.js-locator-input'),
      $coordinates = $form.find('.js-locator-coordinates'),
      $document = $(document),
      cluster = $locator.data('cluster'),
      icon = $locator.data('icon'),
      iconActive = $locator.data('iconActive'),
      $window = $(window),
      markers = [],
      maxZoom = 18,
      isCompleted = true,
      hasDownBeenPressed = false,
      map, defaultBounds, bounds, zoomChangeBoundsListener, infoWindow, hasInfoWindow, $infoWindow, currentMarker, autocomplete;

    hasInfoWindow = $locator.find('.js-locator-infobubble').length ? true : false;

    if (hasInfoWindow) {
      $infoWindow = $locator.find('.js-locator-infobubble');
    }

    // Add markers on map
    var addMarkers = function () {
      // Remove markers
      for (var i = 0, length = markers.length; i < length; i++) {
        markers[i].setMap(null);
      }
      markers = [];

      // Reset bounds
      bounds = new google.maps.LatLngBounds();

      // List markers
      var $items = $list.find('.js-locator-item[data-lat][data-lng]');
      $items.each(function (index) {
        var $item = $(this),
          latitude = $item.data('lat'),
          longitude = $item.data('lng');

        // Create marker
        var position = new google.maps.LatLng(latitude, longitude);
        var marker = new google.maps.Marker({
          position: position,
          animation: google.maps.Animation.DROP,
          icon: {
            url: icon,
            size: new google.maps.Size(28, 37),
            scaledSize: new google.maps.Size(28, 37)
          },
        });

        // Add to map and register events
        marker.setMap(map);

        google.maps.event.addListener(marker, 'click', function () {
          deactivateMarkers();
          currentMarker = marker;
          showDetail($item);
        });

        // Store marker
        markers.push(marker);

        // Extend bounds
        bounds.extend(position);
      });

      centerMap();
    };

    // Create map
    var createMap = function () {
      map = new google.maps.Map($map[0], {
        center: { lat: 48.1047086, lng: -1.6870527 },
        zoom: 14,
        disableDefaultUI: true,
        zoomControl: false,
        styles: googleMapsStyle
      });

      if ($form.length > 0) {
        initAutocomplete();
      }

      if (hasInfoWindow) {
        // Custom infowindow (https://github.com/googlemaps/js-info-bubble)
        infoWindow = new InfoBubble({
          minWidth: 210,
          maxWidth: 210,
          maxHeight: null,
          padding: 0,
          shadowStyle: 0,
          backgroundColor: "#ffee43",
          borderRadius: 0,
          borderWidth: 0,
          arrowSize: 10,
          hideCloseButton: false,
          disableAnimation: true
        });

        // Custom custom infowindow (duh)…
        google.maps.event.addListener(infoWindow, 'domready', function (e) {
          var $closeInfowindow = $('<button type="button" class="c-locator__close">&times;</button>').on('click', function (e) {
            infoWindow.close();
            deactivateMarkers();
          });
          $(this.close_).replaceWith($closeInfowindow);
        });
      }

      // Bounds
      defaultBounds = new google.maps.LatLngBounds({ lat: 46.9243736959, lng: -5.3055002351 }, { lat: 49.1495594394, lng: 2.865746532 });
      centerMap();

      // Markers
      addMarkers();

      // Add a marker clusterer to manage the markers.
      var markerCluster = new MarkerClusterer(map, markers, {
        gridSize: 60,
        maxZoom: maxZoom,
        styles: [{
          url: cluster,
          width: 60,
          height: 60,
          textColor: "#000000",
          textSize: 14,
          fontFamily: 'inherit'
        }]
      });

      // Center map is resized
      google.maps.event.addListener(map, 'resize', function () {
        centerMap();
      });

      // Trigger map resize if window is resized
      $window.on('resize', debounce(function (e) {
        google.maps.event.trigger(map, 'resize');
      }, 200)
      );
    };

    // Center map depending of number of markers
    var centerMap = function () {
      var paddings = {
        top: 150,
        right: 20,
        bottom: 70,
        left: 20
      };

      if ($map.outerHeight() < 500) {
        paddings.top = 70;
      }

      if ($map.outerHeight() >= 700) {
        paddings.bottom = 220;
      }

      if ($map.css('position') == "absolute") {
        paddings.right = map.getDiv().offsetWidth / 2;
        paddings.left = 120;
      }

      if (zoomChangeBoundsListener) {
        google.maps.event.removeListener(zoomChangeBoundsListener);
      }

      if (markers.length == 0 || !$map.data('autofit')) {
        map.fitBounds(defaultBounds, paddings);
      } else {
        // Force setZoom to 14 once after fitBounds
        zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function (event) {
          if (this.getZoom() > maxZoom) {
            this.setZoom(maxZoom);
          }
        });
        map.fitBounds(bounds, paddings);
      }
    };

    // Remove active state of markers
    var deactivateMarkers = function () {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setIcon({ url: icon, size: new google.maps.Size(28, 37), scaledSize: new google.maps.Size(28, 37) });
      }
    }

    // Show item details
    var showDetail = function ($item) {
      currentMarker.setIcon({ url: iconActive, size: new google.maps.Size(28, 37), scaledSize: new google.maps.Size(28, 37) });

      if (hasInfoWindow) {
        var content = updateContent($item);
        infoWindow.setContent(content);
        infoWindow.open(map, currentMarker);
      }
    };

    // Update InfoWindow Content
    var updateContent = function ($item) {
      var content = $infoWindow.html();

      var title = $item.data('title');
      var street = $item.data('street');
      var zipcode = $item.data('zipcode');
      var city = $item.data('city');
      var url = $item.data('url');

      content = content.replace("{{title}}", title);
      content = content.replace("{{content}}", street + "<br>" + zipcode + " " + city);
      content = content.replace("{{url}}", url);

      return content;
    };

    function initAutocomplete() {
      autocomplete = new google.maps.places.Autocomplete($input[0], { types: ['geocode'] });
      autocomplete.addListener('place_changed', fillInAddress);

      $input.on('keydown', function (e) {
        if (e.keyCode === 40) {
          hasDownBeenPressed = true;
        }
      });

      $input.on('keydown', function (e) {
        e.cancelBubble = true;

        if (e.keyCode === 13 || e.keyCode === 9) {
          if (!hasDownBeenPressed && !e.hasRanOnce) {
            var event = new Event('keydown');
            event.code = "ArrowDown";
            event.key = "ArrowDown";
            event.keyCode = 40;
            event.hasRanOnce = true;
            google.maps.event.trigger(e.target, 'keydown', event);
          }
        }
      });

      $form.on('submit', function (e) {
        if(!isCompleted) {
          e.preventDefault();
        }
      });
    }

    function fillInAddress() {
      var place = autocomplete.getPlace();

      if (place.geometry) {
        $coordinates.val(place.geometry.location.lat() + ", " + place.geometry.location.lng());
        isCompleted = true;
        hasDownBeenPressed = false;
      }
    }

    // Load Google Maps once with callback
    var googleMapsLoader = function (callback) {
      var params = [].slice.call(arguments, 1);

      if (window.google && window.google.maps) {
        callback.apply(null, params);
      } else {
        var callbackName = 'callback_' + Math.floor((1 + Math.random()) * 0x10000).toString(16);
        window[callbackName] = function () {
          return callback.apply(null, params);
        };
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?callback=' + callbackName + '&key=' + googleMapsKey + '&libraries=places';
        document.body.appendChild(script);
      }
    };

    // Default Google Maps Style
    var googleMapsStyle = [];

    // Create map once only when visible
    var checkResize = function (e) {
      googleMapsLoader(createMap);
      $window.off('debouncedresize', checkResize);
    };
    $window.on('debouncedresize', checkResize);
    checkResize();
  });

});
