/**
 *
 * Header navigation comportment in small resolutions
 *
 */

(function () {
  var $forms = $('.js-form');
  var $document = $(document);

  $forms.each(function (index, form) {
    var $form = $(form);
    var $anchor = $("#" + $form.data('anchor'));
    var $steps = $form.find('.js-form-step');
    var $toggler = $form.find('.js-form-toggler');
    var $container = $form.find('#' + $toggler.attr('aria-controls'));
    var state = $toggler.attr('aria-expanded') == "true";

    var toggle = function () {
      $toggler.attr('aria-expanded', !state);
      $container.attr('aria-hidden', state);
      state = !state;
    };

    $steps.each(function (index, step) {
      var $step = $(step);
      var $nav = $step.find('.js-form-navigation');

      var update = function (i) {
        $step.attr('aria-hidden', true);
        $($steps[i]).attr('aria-hidden', false);
        $document.scrollTop($anchor.offset().top);
      };

      if(index > 0) {
        $step.attr('aria-hidden', true);
      }

      $nav.on('click', function () {
        update(index + parseInt($nav.attr('data-direction')));
      });
    });

    $toggler.on('click', toggle);
  });
})();
