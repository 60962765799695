/**
 *
 * Menu with subnav expansion
 *
 */

(function () {
  'use strict';

  var $document = $(document);
  var $menus = $(".js-menu");

  $menus.each(function (index, menu) {
    var $menu = $(menu);
    var $togglers = $menu.find('.js-menu-toggler');

    $togglers.each(function (index, toggler) {
      var $toggler = $(toggler);
      var $item = $toggler.parents('.js-menu-item');
      var $target = $('#' + $toggler.attr('aria-controls'));
      var state = $toggler.attr('aria-expanded') == "true";
      var tmp = false;

      var toggle = function () {
        $toggler.attr('aria-expanded', !state);
        state = !state;
      };

      if (Modernizr.touchevents) {
        $toggler.on('click', function (event) {
          toggle();
        });

        $document.on('click', function (event) {
          if (state) {
            var $target = $(event.target);
            var is_inside = $target.closest('.js-menu-item').length > 0;

            if (!is_inside) {
              toggle();
            }
          }
        });
      } else {

        $item.on('mouseenter', function (event) {
          state = false;
          toggle();
        });

        $item.on('mouseleave', function (event) {
          state = true;
          toggle();
        });

        $document.on('keyup', function (event) {
          var is_inside = $(document.activeElement).closest('.js-menu-item').is($item);

          if (!is_inside && tmp) {
            if (state) {
              toggle();
            }
          }

          tmp = is_inside;
        });
      }
    });
  });

})();
