/* Need intersection-observer/intersection-observer.js */

(function () {
  var $window = $(window);

  var observer = new IntersectionObserver(function (observables) {
    var $observables = $(observables);
    $observables.each(function (index, observable) {
      if (observable.intersectionRatio > 0.1 && !observable.target.classList.contains('is-showed')) {
        observable.target.classList.add('is-showed');
      } else {
        observable.target.classList.remove('is-showed');
      }
    });
  }, {
      threshold: [0.1]
    });

  $('.js-figure-animated').each(function (index, figure) {
    observer.observe(figure);
  });

  $('.js-figure-brand').each(function (index, brand) {
    var $brand = $(brand);
    var $figure = $brand.closest('.js-figure');

    var update = function () {
      var top = $figure.offset().top;
      $brand.css('transform', "translateY(-50%) translateY(" + Math.round($window.height() / 2 - top + $window.scrollTop()) + "px)");
    };

    $window.on('scroll', function (event) {
      update();
    });

    $window.on('resize', function (event) {
      update();
    });

    update();
  });
})();
