/* Need  slick-carousel/slick/slick.js */

(function () {
  var $sliders = $('.js-slider');

  $sliders.each(function (index, slider) {
    var $slider = $(slider);
    var $pictures = $slider.find('.js-slider-pictures');
    var $panels = $slider.find('.js-slider-panels');
    var $pagination = $slider.find('.js-slider-pagination');
    var $control = $slider.find('.js-slider-control');
    var state = false;
    var options = {
      infinite: false,
      prevArrow: $slider.find('.js-slider-prev'),
      nextArrow: $slider.find('.js-slider-next'),
      appendDots: $pagination,
      dots: true,
      adaptiveHeight: true,
      autoplay: state,
    };

    if ($control.length > 0) {
      state = $control.data('autoplay');
    }

    if ($panels.length > 0) {
      options.asNavFor = $panels;

      $panels.slick({
        infinite: false,
        arrows: false,
        asNavFor: $pictures,
      });
    }

    $pictures.slick(options);

    $control.on('click', function (event) {
      if (state) {
        $pictures.slick('slickPause');
        $control.attr('aria-pressed', 'false');
      } else {
        $pictures.slick('slickPlay');
        $control.attr('aria-pressed', 'true');
      }

      state = !state;
    });
  });
})();
