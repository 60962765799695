(function () {
  var $dropdowns = $('.js-dropdown');
  var $document = $(document);

  $dropdowns.each(function (index, dropdown) {
    var $dropdown = $(dropdown);
    var id = $dropdown.attr('id');
    var $button = $dropdown.find('#' + id + "-dropdown-button");
    var $menu = $dropdown.find('#' + $button.attr('aria-controls'));
    var state = $button.attr('aria-expanded');

    var toggle = function () {
      $button.attr('aria-expanded', !state);
      $menu.attr('aria-hidden', state);
      state = !state;
    };

    var show = function () {
      $button.attr('aria-expanded', true);
      $menu.attr('aria-hidden', false);
      state = true;
    };

    var hide = function () {
      $button.attr('aria-expanded', false);
      $menu.attr('aria-hidden', true);
      state = false;
    };

    $button.on('click', function (event) {
      toggle();
    });

    $button.on("keyup", function (event) {
      event.preventDefault();
      if (event.keyCode === 13 || event.keyCode === 32) { // Enter or space
        toggle();
      }
    });

    $document.on("click", function (event) {
      var target = event.target;

      do {
        if (target == dropdown) {
          return;
        }
        target = target.parentNode;
      } while (target);

      hide();
    });
  });
})();
