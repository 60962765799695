/**
 *
 * Block component with collapsing comportment in mobile
 *
 */

(function () {
  var $window = $(window);
  var $blocks = $('.js-block');


  $blocks.each(function (index, block) {
    /* Variables */
    var $block = $(block);
    var $summary = $block.find('.js-block-summary');
    var $content = $block.find('.js-block-content');
    var id = $block.attr('id');
    var is_collapsible = $summary.css('position') == "relative";
    var state = false;
    var tmp = !is_collapsible;

    /* Functions */
    var init = function () {
      if ($content.css('display') == "none") {
        $summary
          .attr('role', 'button')
          .attr('tabindex', 0)
          .attr('id', id + '-summary')
          .attr('aria-expanded', 'false')
          .attr('aria-controls', 'false');

        $content
          .attr('id', id + '-content')
          .attr('aria-hidden', 'true');

        state = false;

        if (index == 0) {
          $summary
            .attr('aria-expanded', 'true')
            .attr('aria-controls', 'true');

          $content.attr('aria-hidden', 'false');

          state = true;
        }
      } else {
        $summary
          .attr('role', null)
          .attr('tabindex', null)
          .attr('id', null)
          .attr('aria-expanded', null)
          .attr('aria-controls', null);

        $content
          .attr('id', null)
          .attr('aria-hidden', null);

        state = true;
      }

      tmp = is_collapsible;
    };

    var toggle = function () {
      $summary.attr('aria-expanded', !state);
      $content.attr('aria-hidden', state);
      state = !state;
    };

    /* Listeners */
    $summary.on('click', function () {
      if (is_collapsible) {
        toggle();
      }
    });

    $window.on('resize', function () {
      is_collapsible = $summary.css('position') == "relative";

      if (tmp != is_collapsible) {
        init();
      }
    });

    init();
  });
})();
