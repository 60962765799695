/* Need window.requestanimationframe/requestanimationframe.js */

(function () {
  var $window = $(window);
  var $document = $(document);
  var $body = $('body');
  var $header = $('#header');
  var $navigation = $('#navigation');
  var $button = $('#navigation-button');
  var $quicklinks = $('#' + $button.attr('aria-controls'));
  var is_pinnable, is_pinned, was_pinned, header_height, scroll_top, need_update, state;

  was_pinned = false;
  state = false;

  var loop = function () {
    if (need_update) {
      set_variables();

      if (was_pinned != is_pinned) {
        update_sticky();
      }

      was_pinned = is_pinned;
    }
    requestAnimationFrame(loop);
  };

  var set_variables = function () {
    is_pinnable = ($navigation.css("position") == "sticky" || $navigation.css("position") == "-webkit-sticky");

    if (is_pinnable) {
      header_height = Math.round($header.outerHeight());
      scroll_top = Math.round($document.scrollTop());
      is_pinned = scroll_top >= header_height;
    } else {
      is_pinned = false;
    }

    need_update = false;
  };

  var update_sticky = function () {
    if (is_pinned) {
      $navigation.addClass('is-pinned');
    } else {
      $navigation.removeClass('is-pinned');
    }
  };

  var update_quicklinks = function () {
    if ($button.css('display') == "none") {
      $button.attr('aria-expanded', false);
      $quicklinks.attr('aria-hidden', false);
    } else {
      $button.attr('aria-expanded', "false");
      $quicklinks.attr('aria-hidden', "true");
    }
    state = false;
    $body.removeClass('no-scroll');
  };

  var init = function () {
    set_variables();
    update_quicklinks();
    requestAnimationFrame(loop);
  }

  $window.on('resize', function () {
    need_update = true;
    update_quicklinks();
  });

  $document.on('scroll', function () {
    need_update = true;
  });

  $button.on('click', function () {
    $button.attr('aria-expanded', !state);
    $quicklinks.attr('aria-hidden', state);
    state = !state;

    if (state) {
      $body.addClass('no-scroll');
    } else {
      $body.removeClass('no-scroll');
    }
  });

  init();
})();
