/* Need  slick-carousel/slick/slick.js */

(function () {
  var $sliders = $('.js-cards-slider');

  $sliders.each(function (index, slider) {
    var $slider = $(slider);
    var $items = $slider.find('.js-cards-slider-items');
    var $hint = $slider.find('.js-cards-slider-hint');
    var options = {
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      variableWidth: true
    };
    var carousel;

    var update = function () {
      var container_width = $slider.find('.slick-list').width();
      var track_width = 0;

      $slider.find('.slick-slide').each(function (index, slide) {
        track_width += $(slide).width();
      });

      if(track_width > container_width) {
        $hint.removeClass('is-hidden');
      } else {
        $hint.addClass('is-hidden');
      };
    };

    $hint.on('click', function(){
      var current = carousel.slick("getSlick").currentSlide;
      var total = carousel.slick("getSlick").slideCount;

      if(current + 1 < total) {
        carousel.slick('slickNext');
      } else {
        carousel.slick("getSlick").goTo(0);
      }
    });

    $items.on('setPosition', function (event, slick) {
      update();
    });

    carousel = $items.slick(options);
  });
})();
