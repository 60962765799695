/* Need  window.requestanimationframe/requestanimationframe.js */

(function () {
  var $pastil = $('#pastil');

  if($pastil.length > 0) {
    var $window = $(window);
    var $curve = $pastil.find('#curve');
    var string = $curve.attr('transform');
    var array = string.split('(').join(',').split(')').join(',').split(' ').join(',').split(',');
    var cx = array[2];
    var cy = array[3];
    var rotate = parseInt(array[1]);
    var scroll = $window.scrollTop();
    var temp = $window.scrollTop() + 1;

    var loop = function() {
      if(temp != scroll) {
        temp = temp + (scroll - temp) / 10;

        $curve.attr('transform', 'rotate('+ (rotate + temp) + ',' + cx + ',' + cy +')');
      }
      window.requestAnimationFrame(loop);
    };

    window.requestAnimationFrame(loop);

    $window.on('scroll', function(e) {
      scroll = $window.scrollTop();
    });

    $window.on('resize', function(e) {
      scroll = $window.scrollTop();
    });
  }
})();
