/**
 *
 * Header navigation comportment in small resolutions
 *
 */

(function () {
  var $window = $(window);
  var $header = $('#header');

  $header.find('.js-header-burger').each(function (index, burger) {
    var $burger = $(burger);
    var $navigation = $('#' + $burger.attr('aria-controls'));
    var state = $burger.attr('aria-expanded');

    var toggle_navigation = function () {
      $burger.attr('aria-expanded', !state);
      $navigation.attr('aria-hidden', state);
      state = !state;
    };

    var show_navigation = function () {
      $burger.attr('aria-expanded', "true");
      $navigation.attr('aria-hidden', "false");
      state = true;
    };

    var hide_navigation = function () {
      $burger.attr('aria-expanded', "false");
      $navigation.attr('aria-hidden', "true");
      state = false;
    };

    var update = debounce(function () {
      init();
    }, 250);

    var init = function () {
      $burger.css("display") == "none" ? show_navigation() : hide_navigation();
    };

    $burger.on('click', toggle_navigation);

    $window.on('resize', update);

    init();
  });
})();
